/* @import "~@angular/material/prebuilt-themes/indigo-pink.css"; */

@import url('@scania/tegel/dist/tegel/tegel.css');


/* You can add global styles to this file, and also import other style files */

.wrapper {
    width: 100%;
    height: 100%;
}

.overlay {
    position: absolute;
    z-index: 1002;
    background-color: rgba(255, 255, 255, 0.5);
    width: 100%;
    height: 100%;
}

.spinner-wrapper {
    margin-top: 0px;
    display: flex;
    justify-content: center;
    justify-items: center;
}

#content-desktop {display: block;}
#content-mobile-landscape {display: none;}
#content-mobile-portrait {display: none;}
#content-mobile {display: none;}

@media screen and (max-width: 500px) {
    #content-desktop {display: none;}
    #content-mobile-landscape {display: none;}
    #content-mobile-portrait {display: block;}
    #content-mobile {display: block;}
}

@media screen and (min-width: 500px) and (max-width: 1023px){
    #content-desktop {display: none;}
    #content-mobile-landscape {display: block;}
    #content-mobile-portrait {display: none;}
    #content-mobile {display: block;}
}
.tooltip-normal {
    font-size: 1.0em;
    background-color:darkgoldenrod;
    white-space: pre;
    min-width: -webkit-fill-available;
    /* min-width: fit-content; */
    max-width: 600px;
}
.snackbar_error {
    background-color: rgb(177, 67, 67, 0.85) !important;
    color: white;
    vertical-align: text-top;
    border: 1px solid black;
    border-radius: 5px !important;
    white-space: pre !important;
    min-width: -webkit-fill-available !important;
    /* min-width: fit-content !important; */
    max-width: 800px !important;
}

.snackbar_info {
    background-color: rgba(146, 175, 214, 0.85) !important;
    color: black;
    vertical-align: text-top;
    border: 1px solid black;
    border-radius: 5px !important;
    white-space: pre !important;
    min-width: -webkit-fill-available !important;
    /* min-width: fit-content !important; */
    max-width: 800px !important;
}

/* override angular material snackbar class mat-simple-snackbar*/
.mat-simple-snackbar {
    font-size: 0.95em !important;
    padding: 15px !important;
  }
/* override angular material snackbar class mat-simple-snackbar-action (for action button)*/
.mat-simple-snackbar-action {
    color: rgb(0, 0, 0);
    background-color: #ffffffa9;
    border-radius: 5px !important;
    font-weight: bold;
}

.wbt-spacing-left-8 {
    margin-left: var(--tds-spacing-layout-8);
 }

 .wbt-spacing-right-8 {
    margin-right: var(--tds-spacing-layout-8);
 }

 .wbt-spacing-top-8 {
    margin-top: var(--tds-spacing-layout-8);
 }

 .wbt-spacing-bottom-8 {
    margin-bottom: var(--tds-spacing-layout-8);
 }

 .wbt-spacing-left-16 {
    margin-left: var(--tds-spacing-layout-16);
 }

 .wbt-spacing-right-16 {
    margin-right: var(--tds-spacing-layout-16);
 }

 .wbt-spacing-top-16 {
    margin-top: var(--tds-spacing-layout-16);
 }

 .wbt-spacing-bottom-16 {
    margin-bottom: var(--tds-spacing-layout-16);
 }

 .img-filter {
    opacity: 0.2;
 }

  .img-filter.active {
    opacity: 1;
 }
